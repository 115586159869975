<template>
    <router-view />
</template>

<style>
* {
  font-weight: 400;
  font-family: 'Work Sans', sans-serif;
}
h1 {
  font-family: 'Lora', serif;  
  font-weight: 700;
  font-size: 60px;
}
h2 {
  font-family: 'Work Sans', serif;  
  font-weight: 800;
  font-size: 26px;
}
</style>